import * as _ from '@technically/lodash'

import { GLOVES, GLOVE_ASSET_DICT, LEATHERS, WEBS } from '../../common/sheets'
import generateSnapshots, { viewNames } from './generate'

const HEIGHT = 1024

const getJob = ({ filename, leather, glove, web, viewName }) => {
  const controlTreeChanges = [
    ['product.leather', leather.id],
    ['product.glove', glove.id],
    ['colors.web.style', web.id],
    ['colors.laces.fingerWeb', 'metallicGold'],
    ['colors.laces.heel', 'metallicSilver'],
    ['colors.binding', 'metallicGold'],
    ['colors.welting.back', 'metallicGold'],
    ['colors.welting.palm', 'metallicSilver'],
    ['colors.shellBack.leatherColor', 'navy'],
    ['colors.trim.color', 'navy'],
    ['colors.shellPalm', 'navy'],
    ['colors.web.color', 'navy'],
    ['colors.stamping', 'metallicGold'],
  ]

  const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
  const sourceSize = gloveAsset.props.imageDimensions[viewName]
  const height = HEIGHT
  const width = Math.round((height / sourceSize.height) * sourceSize.width)
  const size = {
    width,
    height,
  }

  return { filename, controlTreeChanges, viewName, size }
}

const main = async (filters) => {
  const gloves =
    filters?.gloveId ?
      GLOVES.filter((glove) => glove.id === filters.gloveId)
    : GLOVES
  const leathers = LEATHERS
  const webs = WEBS

  let jobs = []
  const jobIds = []
  _.forEach(gloves, (glove) => {
    const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
    _.forEach(leathers, (leather) => {
      _.forEach(viewNames, (viewName) => {
        if (!glove.limitations.leatherId[leather.id]) {
          return
        }
        _.forEach(webs, (web) => {
          if (!glove.limitations.webIds[web.id]) {
            return
          }
          const jobId = `${gloveAsset.id}-${web.id}-${viewName}`
          if (_.includes(jobIds, jobId)) {
            return
          }
          jobIds.push(jobId)

          const filename = `${viewName}   ${gloveAsset.id}   ${web.id}   ${leather.props.sku}${glove.id}`
          const job = getJob({
            filename,
            leather,
            glove,
            web,
            viewName,
          })
          jobs.push(job)
        })
      })
    })
  })
  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
